<template>
  <div id="app">
    <NHeader :theme="headerTheme" />
    <div class="section">
      <keep-alive exclude="newsDetail,goodsDetail">
        <router-view />
      </keep-alive>
    </div>
    <NFooter />
  </div>
</template>
<script>
import NHeader from '@/components/NHeader/index.vue'
import NFooter from '@/components/NFooter/index.vue'

export default {
  components: {
    NHeader,
    NFooter,
  },
  computed: {
    headerTheme() {
      return ['/whoarewe', '/newsarticles', '/newsDetail', '/goodsDetail'].some(
        (e) => this.$route.path.indexOf(e) > -1
      )
        ? 'white'
        : 'green'
    },
  },
}
</script>

<style lang="less">
html,
body {
  height: 100%;
  background-color: #f1f1f1;
}
#app {
  position: relative;
  min-height: 100%;
  height: auto !important;
  height: 100%;
  .section {
    padding-bottom: 555px;
  }
}
</style>
