<template>
  <div class="header">
    <div class="logo">
      <h1 class="title" @click="goNext('/')">
        <span class="hide">genuineplant.com</span>
        <img class="logo-img" src="/images/logo.png" alt="logo" />
      </h1>
    </div>
    <ul
      class="navs"
      :class="{ green: theme === 'green', white: theme === 'white' }"
    >
      <li
        @click="goNext(item.path)"
        v-for="(item, index) in navList"
        :key="index"
        class="nav-item"
        :class="{ active: $route.path === item.path }"
      >
        {{ item.text }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    theme: {
      require: true,
      default: 'green',
    },
  },

  data() {
    return {
      navList: [
        {
          text: 'Mushroom Extracts',
          path: '/mushroomextracts',
        },
        {
          text: 'Nutraceutical',
          path: '/nutraceutical',
        },
        {
          text: 'Cosmetic Materials',
          path: '/cosmeticmaterials',
        },
        {
          text: 'Natural Colors',
          path: '/naturalcolors',
        },
        {
          text: 'Who Are We',
          path: '/whoarewe',
        },
        {
          text: 'News & Articles',
          path: '/newsarticles',
        },
      ],
      cateList: [],
      active: 0,
    }
  },
  methods: {
    goNext(path) {
      this.$router.push(path)
    },
  },
}
</script>

<style lang="less" scoped>
@import '@/assets/css/var.less';
.header {
  height: 104px;
  display: flex;
  .logo {
    cursor: pointer;
    background-color: #fff;
    &.green {
      background-color: @main-color;
    }
    &.white {
      background-color: #fff;
    }
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 360px;
    .title {
      margin: 0;
      .hide {
        display: none;
      }
      .logo-img {
        width: 266px;
        height: 46px;
        display: block;
      }
    }
  }
  .navs {
    flex: 1;
    display: flex;
    .nav-item {
      height: 100%;
      display: flex;
      align-items: center;
      transition: all 0.3s;
      padding: 20px;
      box-sizing: border-box;
      font-weight: bold;

      &:hover {
        cursor: pointer;
      }
    }
  }
  .green {
    background-color: @main-color;
    .nav-item {
      color: #fff;
      &:hover {
        background-color: #fff;
        color: @main-color;
      }
      &.active {
        background-color: #fff;
        color: @main-color;
      }
    }
  }
  .white {
    background-color: #fff;
    .nav-item {
      color: @main-color;
      &:hover {
        background-color: @main-color;
        color: #fff;
      }
      &.active {
        background-color: @main-color;
        color: #fff;
      }
    }
  }
}
</style>
