import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function (location) {
  return routerPush.call(this, location).catch((err) => {})
}

const routes = [
  {
    path: '/',
    component: () => import('@/views/Home'),
  },
  {
    path: '/mushroomextracts',
    name: 'mushroom extracts',
    component: () => import('@/views/MushroomExtracts'),
  },
  {
    path: '/nutraceutical',
    name: 'nutraceutical',
    component: () => import('@/views/Nutriceticles'),
  },
  {
    path: '/cosmeticmaterials',
    name: 'cosmetic materials',
    component: () => import('@/views/CosmeticMaterials'),
  },
  {
    path: '/naturalcolors',
    name: 'natural colors',
    component: () => import('@/views/NaturalColors'),
  },
  {
    path: '/whoarewe',
    component: () => import('@/views/WhoAreWe'),
  },
  {
    path: '/newsarticles',
    component: () => import('@/views/NewsArticles'),
  },
  {
    path: '/goodsDetail/:id',
    name: 'goodsDetail',
    component: () => import('@/views/GoodsDetail'),
  },
  {
    path: '/newsDetail/:id',
    name: 'newsDetail',
    component: () => import('@/views/NewsDetail'),
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior: () => ({ y: 0 }),
})

export default router
