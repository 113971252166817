import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dayjs from 'dayjs'
import '@/assets/css/reset.less'
import '@/assets/css/font/iconfont.less'

import { Pagination } from 'element-ui'

Vue.config.productionTip = false

Vue.use(Pagination)

Vue.filter('toUpper', (val) => {
  return val.toUpperCase()
})
Vue.filter('dateFormat', (val) => {
  return dayjs(val).format('DD MMM YYYY')
})
Vue.filter('dateFormatTime', (val) => {
  return dayjs(val).format('DD MMM YYYY HH:mm:ss')
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
