<template>
  <div class="footer">
    <div class="footer-container">
      <dl class="contact-list">
        <dt class="list-title">CONTACT US</dt>
        <dd>Genuine Plant Products Inc.</dd>
        <dd>info@genuineplant.com</dd>
        <dd>+86 159 9178 9107</dd>
        <dd>www.genuineplant.com</dd>
        <dd>
          51 Fengcheng 9th Road, Weiyang District, Xi'an, Shaanxi, China 710016
        </dd>
      </dl>
      <dl class="product-list">
        <dt class="list-title">PRODUCTS</dt>
        <dd
          v-for="(item, index) in navList"
          :key="index"
          @click="goPage(item.path)"
        >
          {{ item.text }}
        </dd>
      </dl>
    </div>
    <div class="copy-right">
      Copyright All Rights Reserved:xxxxx xx xx., Ltd. Power by SionArt.
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        {
          text: 'Mushroom Extracts',
          path: '/mushroomextracts',
        },
        {
          text: 'Nutriceticles',
          path: '/nutriceticles',
        },
        {
          text: 'Cosmetic Materials',
          path: '/cosmeticmaterials',
        },
        {
          text: 'Natural Colors',
          path: '/naturalcolors',
        },
      ],
    }
  },
  methods: {
    goPage(path) {
      this.$router.push(path)
    },
  },
}
</script>

<style lang="less" scoped>
.footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 555px;
  background-color: #171717;
  .footer-container {
    display: flex;
    width: 1218px;
    padding-top: 86px;
    box-sizing: border-box;
    margin: 0 auto;
    color: #fff;
    border-bottom: 1px solid #8b8b8b;
    margin-bottom: 38px;
    .product-list {
      margin-left: 65px;
      dd {
        position: relative;
        padding-left: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        &::after {
          position: absolute;
          left: 0;
          content: '';
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background-color: #fff;
        }
      }
    }
    .list-title {
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 44px;
    }
    dd {
      margin-bottom: 38px;
    }
  }
  .copy-right {
    text-align: center;
    color: #8b8b8b;
    font-weight: bold;
  }
}
</style>
